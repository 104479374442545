import React, { ReactNode } from "react";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  FieldBinaryOutlined,
  FileImageOutlined,
  FontColorsOutlined,
  HeartOutlined,
  InfoCircleOutlined,
  LikeOutlined,
  MailOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  StarOutlined,
  TableOutlined,
} from "@ant-design/icons";
import ChoicesTemplate from "../pages/surveys/edit/steps/questions/answerTemplates/choices";
import CsiTemplate from "../pages/surveys/edit/steps/questions/answerTemplates/csi";
import NpsTemplate from "../pages/surveys/edit/steps/questions/answerTemplates/nps";
import CustomTemplate from "../pages/surveys/edit/steps/questions/answerTemplates/custom";
import { I18n } from "react-redux-i18n";
import { SurveyLanguage } from "./language";
import EndBlock from "../pages/surveys/edit/steps/questions/answerTemplates/endBlock";
import { ScoreLabelInputComponent } from "../components/scoreLabelInput";
import RankingTemplate from "../pages/surveys/edit/steps/questions/answerTemplates/ranging";

export enum TemplateEnum {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  CSI = "CSI",
  NPS = "NPS",
  LOYALTY_INDEX = "LOYALTY_INDEX",
  RANGING = "RANGING",
  FILE_UPLOAD = "FILE_UPLOAD",
  INFO_BLOCK = "INFO_BLOCK",
  START_BLOCK = "START_BLOCK",
  END_BLOCK = "END_BLOCK",
  EMAIL = "EMAIL",
  IIN = "IIN",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  DATE = "DATE",
  DATETIME = "DATETIME",
}

export const templateIcons: Record<TemplateEnum, ReactNode> = {
  [TemplateEnum.SINGLE_CHOICE]: <CheckCircleOutlined />,
  [TemplateEnum.MULTIPLE_CHOICE]: <CheckSquareOutlined />,
  [TemplateEnum.CSI]: <TableOutlined />,
  [TemplateEnum.NPS]: <StarOutlined />,
  [TemplateEnum.LOYALTY_INDEX]: <HeartOutlined />,
  [TemplateEnum.RANGING]: <OrderedListOutlined />,
  [TemplateEnum.FILE_UPLOAD]: <FileImageOutlined />,
  [TemplateEnum.INFO_BLOCK]: <MessageOutlined />,
  [TemplateEnum.START_BLOCK]: <InfoCircleOutlined />,
  [TemplateEnum.END_BLOCK]: <LikeOutlined />,
  [TemplateEnum.EMAIL]: <MailOutlined />,
  [TemplateEnum.IIN]: <FieldBinaryOutlined />,
  [TemplateEnum.NUMBER]: <PhoneOutlined />,
  [TemplateEnum.TEXT]: <FontColorsOutlined />,
  [TemplateEnum.DATE]: <CalendarOutlined />,
  [TemplateEnum.DATETIME]: <ClockCircleOutlined />,
};

export const getTemplateLabels = (): Record<TemplateEnum, string> => ({
  [TemplateEnum.SINGLE_CHOICE]: I18n.t("singleChoice"),
  [TemplateEnum.MULTIPLE_CHOICE]: I18n.t("multipleChoice"),
  [TemplateEnum.CSI]: I18n.t("matrixCsi"),
  [TemplateEnum.NPS]: I18n.t("npsRatingScale"),
  [TemplateEnum.LOYALTY_INDEX]: I18n.t("loyaltyIndex"),
  [TemplateEnum.RANGING]: I18n.t("ranging"),
  [TemplateEnum.FILE_UPLOAD]: I18n.t("fileUpload"),
  [TemplateEnum.INFO_BLOCK]: I18n.t("message"),
  [TemplateEnum.START_BLOCK]: I18n.t("welcomePage"),
  [TemplateEnum.END_BLOCK]: I18n.t("thankYouPage"),
  [TemplateEnum.EMAIL]: I18n.t("email"),
  [TemplateEnum.IIN]: I18n.t("iin"),
  [TemplateEnum.NUMBER]: I18n.t("phone"),
  [TemplateEnum.TEXT]: I18n.t("text"),
  [TemplateEnum.DATE]: I18n.t("date"),
  [TemplateEnum.DATETIME]: I18n.t("dateAndTime"),
});

export const templateComponents = (
  langIndex: number,
  lang: SurveyLanguage,
  redirectToAnotherSite = false,
): Record<TemplateEnum, ReactNode> => {
  const props = { langIndex, lang };
  return {
    [TemplateEnum.SINGLE_CHOICE]: (
      <ChoicesTemplate {...props} templateType={TemplateEnum.SINGLE_CHOICE as TemplateEnum} />
    ),
    [TemplateEnum.MULTIPLE_CHOICE]: (
      <ChoicesTemplate {...props} templateType={TemplateEnum.MULTIPLE_CHOICE as TemplateEnum} />
    ),
    [TemplateEnum.CSI]: <CsiTemplate {...props} />,
    [TemplateEnum.NPS]: (
      <>
        <NpsTemplate />
        <ScoreLabelInputComponent currentLangIndex={langIndex} lang={lang} />
      </>
    ),
    [TemplateEnum.LOYALTY_INDEX]: (
      <>
        <ScoreLabelInputComponent currentLangIndex={langIndex} lang={lang} />
        <CustomTemplate />
      </>
    ),
    [TemplateEnum.RANGING]: <RankingTemplate {...props} />,
    [TemplateEnum.FILE_UPLOAD]: <CustomTemplate />,
    [TemplateEnum.INFO_BLOCK]: <></>,
    [TemplateEnum.START_BLOCK]: <></>,
    [TemplateEnum.END_BLOCK]: <EndBlock redirectToAnotherSite={redirectToAnotherSite} />,
    [TemplateEnum.EMAIL]: <CustomTemplate />,
    [TemplateEnum.IIN]: <CustomTemplate />,
    [TemplateEnum.NUMBER]: <CustomTemplate />,
    [TemplateEnum.TEXT]: <CustomTemplate showLineOptions showRegexOptions />,
    [TemplateEnum.DATE]: <CustomTemplate />,
    [TemplateEnum.DATETIME]: <CustomTemplate />,
  };
};
