export const RuTranslations = {
  surveys: "Опросы",
  survey: "Опрос",
  questions: "Вопросы",
  question: "Вопрос",
  answer: "Ответ",
  settings: "Настройки",
  create: "Создать",
  cancel: "Отмена",
  save: "Сохранить",
  show: "Просмотреть",
  back: "Назад",
  delete: "Удалить",
  tags: "Метки",
  select: "Выберите",
  enter: "Введите",
  oneOf: "Один из",
  general: "Общее",
  schedule: "Расписание",
  respondents: "Получатели",
  search: "Поиск",
  generateQr: "Сгенерировать QR",
  searchBySurveys: "Поиск по опросам",
  questionnaireQuestions: "Вопросы анкеты",
  personalization: "Оформление",
  integrations: "Интеграции",
  results: "Результаты",
  date: "Дата",
  time: "Время",
  days: "Дни недели",
  monday: "Понедельник",
  tuesday: "Вторник",
  wednesday: "Среда",
  thursday: "Четверг",
  friday: "Пятница",
  saturday: "Суббота",
  sunday: "Воскресенье",
  mondayShortLabel: "Пн",
  tuesdayShortLabel: "Вт",
  wednesdayShortLabel: "Ср",
  thursdayShortLabel: "Чт",
  fridayShortLabel: "Пт",
  saturdayShortLabel: "Сб",
  sundayShortLabel: "Вс",
  today: "Сегодня",
  dailyDistributionSize: "Размер ежедневной рассылки",
  exclusionDates: "Даты исключения",
  exclusionDatesHelpTxt: "Данная функция позволяет отметить даты, когда рассылка опросов абонентам запрещена.",
  apiKey: "API-Ключ",
  apiKeyDeleteConfirmation: "Вы уверены, что хотите удалить API-Ключ ？",
  apiKeyHelpTxt:
    "Функция позволяет управлять API ключами необходимыми для авторизации API запросов на отправку опросов.",
  description: "Описание",
  actions: "Действия",
  action: "Действие",
  apiKeyCopiedToCopyBoard: "API-Ключ скопирован в буфер обмена.",
  notOneOf: "Любой исключая",
  createSurvey: "Создать опрос",
  fromTemplate: "Из шаблона",
  surveyName: "Наименование опроса",
  surveyNameTooltip: "Наименование опроса отображаемое ввиде заголовка на каждой странице опроса для респондента.",
  type: "Тип",
  recipientsSource: "Источник получателей",
  respondentsListSource: "Источник списка респондентов",
  created: "Создал",
  createDate: "Дата создания",
  endDate: "Дата завершения",
  startDate: "Дата старта",
  status: "Статус",
  subscribersDb: "Контакты",
  file: "Файл",
  startSurvey: "Запустить опрос",
  startSurveyConfirmation: "Вы уверены, что хотите начать опрос ？",
  endSurvey: "Завершить опрос",
  endSurveyConfirmation: "Вы уверены, что хотите завершить опрос ？",
  pauseSurvey: "Приостановить опрос",
  pauseSurveyConfirmation: "Вы уверены, что хотите приостановить опрос ？",
  filtration: "Фильтрация",
  bySubscriberProfile: "По профилю абонента",
  bySurveyProps: "По параметрам опроса",
  addFiltersGroup: "Добавить группу фильтров",
  countSubscribers: "Рассчитать кол-во респондентов в выборке",
  selectFile: "Выбрать файл",
  filtersGroupHelpText: "Вы можете создать несколько групп фильтров, которые будут работать по логике ИЛИ",
  questionName: "Наименование вопроса",
  template: "Шаблон",
  noData: "Нет данных",
  createCopy: "Создать копию",
  copyLink: "Скопировать ссылку",
  createDuplicate: "Создать дубликат",
  createDuplicateConfirmation: "Вы уверены, что хотите создать дубликат опроса ？",
  deleteQuestion: "Удалить вопрос",
  deleteQuestionConfirmation: "Вы уверены, что хотите удалить вопрос ？",
  edit: "Редактировать",
  addTag: "Добавить метку",
  addFilter: "Добавить фильтр",
  questionText: "Текст вопроса ",
  questionTextOnEn: "Текст вопроса на английском",
  questionTextOnKk: "Текст вопроса на казахском",
  questionTextOnRu: "Текст вопроса на русском",
  questionTextOnFrench: "Текст вопроса на французском",
  questionTextOnTurkish: "Текст вопроса на турецком",
  questionTextOnUzbek: "Текст вопроса на узбекском",
  onEn: "На английском",
  onKk: "На казахском",
  onRu: "На русском",
  onFrench: "На французском",
  onTurkish: "На турецком",
  onUzbek: "На узбекском",
  useCondition: "Использовать условие",
  singleChoice: "Один из списка",
  multipleChoice: "Несколько из списка",
  textAnswer: "Текстовый ответ",
  infoBlock: "Инфо блок",
  endBlock: "Завершающий блок",
  matrixCsi: "CSI (Индекс Удовлетворённости Клиентов)",
  npsRatingScale: "Рейтинг",
  other: "Другое",
  add: "Добавить",
  params: "Параметры",
  param: "Параметр",
  criteria: "Критерии",
  gradeTxt: "Текст оценки",
  gradeTxtOnEn: "Текст оценки на английском",
  gradeTxtOnKk: "Текст оценки на казахском",
  gradeTxtOnRu: "Текст оценки на русском",
  gradeTxtOnFrench: "Текст оценки на французском",
  gradeTxtOnTurkish: "Текст оценки на турецком",
  gradeTxtOnUzbek: "Текст оценки на узбекском",
  balls: "Баллов",
  yes: "Да",
  no: "Нет",
  yesNo: "Да / Нет",
  ratingScale: "Шкала оценки",
  blockType: "Тип блока",
  information: "Информационный",
  final: "Завершающий",
  answerType: "Тип ответа",
  inputField: "Поле ввода",
  inputFieldFormat: "Формат поля ввода",
  oneLine: "Однострочное",
  multiLine: "Многострочное",
  phone: "Телефон",
  answerRequired: "Ответ обязателен",
  icon: "Иконка",
  textValues: "Текстовые значения",
  grade: "Оценка",
  min: "Мин.",
  max: "Макс.",
  logo: "Лого",
  logoMinSizeHelpText: "Мин. размер лого: ",
  logoMaxSizeHelpText: "Макс. размер лого: ",
  logoMaxWeightHelpText: "Макс. вес файла: ",
  faviconMaxSizeHelpText: "Макс. размер Favicon: ",
  themeColor: "Корпоративный цвет",
  surveyMetaName: "Наименование вкладки в браузере",
  surveyMetaDescription: "Описание опроса",
  next: "Далее",
  answerVariable: "Вариант ответа",
  personalizationHelpText: "Выберите оформление для своих опросов. Логотип будет отображен в левом верхнем углу.",
  errorLabel: "Произошла ошибка",
  errorWhileSaving: "Произошла ошибка при сохранении ",
  requiredMessage: "Обязательно для заполнения",
  successful: "Успешно",
  logout: "Выйти",
  userName: "Имя пользователя",
  password: "Пароль",
  pleaseEnter: "Пожалуйста, введите",
  signIn: "Войти",
  authError: "Неверные данные для входа. Пользователь не найден или пароль не верен.",
  deleteSurvey: "Удалить опрос",
  deleteSurveyConfirmation: "Вы уверены, что хотите удалить опрос ？",
  new: "Новый",
  draft: "Черновик",
  active: "Активен",
  paused: "На паузе",
  finished: "Завершен",
  deleted: "Удален",
  reset: "Сбросить",
  surveyType: "Тип опроса",
  subscribers: "Респондентов",
  areYouSure: "Вы уверены ？",
  qrCode: "QR-Код",
  fillRequiredFields: "Заполните обязательные поля",
  requiredField: "Обязательно для заполнения",
  useDefaultColor: "Использовать цвет по умолчанию",
  maxCharacters: "Макс. символов: 50",
  changesSaved: "Изменения сохранены",
  acceptTypesFormat: "Поддерживаемые форматы файлов",
  metaDescriptionDefaultText: "Приглашаем вас пройти опрос по ссылке",
  selectLogo: "Выбрать лого",
  selectFavicon: "Выберите Favicon",
  selectIcon: "Выберите иконку",
  callOn: "Событие",
  method: "Метод",
  deleteWebhook: "Удалить Webhook",
  deleteWebhookConfirmation: "Вы уверены, что хотите удалить Webhook ？",
  createWebhook: "Создать Webhook",
  request: "Запрос",
  authorization: "Авторизация",
  authorizationInfo: "Данные авторизации",
  headers: "Заголовки",
  key: "Ключ",
  value: "Значение",
  format: "Формат",
  requestBody: "Тело запроса",
  enterPassword: "Введите пароль",
  enterOldPassword: "Введите старый пароль",
  enterNewPassword: "Введите новый пароль",
  confirmPassword: "Подтвердите пароль",
  confirm: "Подтвердить",
  passwordChanged: "Пароль успешно изменён",
  oldPassword: "Старый пароль",
  newPassword: "Новый пароль",
  passwordsNotEqual: "Пароли не совпадают",
  apiKeys: "API Ключи",
  exceptionDates: "Даты исключения",
  selectDate: "Выберите дату",
  deleteDate: "удалить дату",
  deleteDateConfirmation: "Вы уверены, что хотите удалить дату ？",
  cancelAllChangesConfirmation: "Вы уверены, что хотите отменить все изменения ？",
  createQuestion: "Создать вопрос",
  enterID: "Введите идентификатор",
  byDefault: "По умолчанию",
  close: "Закрыть",
  surveyStarting: "Начало опроса",
  surveyEnding: "Завершение опроса",
  dbSubscribersCount: "Количество получателей из БД",
  sbSubscribersHelpTxt: "Количество получателей рассылки",
  respondentsCount: "Количество респондентов",
  respondentsHelpTxt: "Количество респондентов, открывших опрос или отсканировавших QR-код",
  participantsCount: "Количество участников",
  participantsHelpTxt: "Количество респондентов, ответивших хотя бы на один вопрос",
  endedSurveyCount: "Закончили опрос",
  endedSurveyHelpTxt: "Количество респондентов, ответивших на все вопросы",
  download: "Скачать",
  downloadCsv: "Скачать CSV",
  downloadExcel: "Скачать Excel",
  answerText: "Текст ответа",
  answerTextOnEn: "Текст ответа на английском",
  answerTextOnKk: "Текст ответа на казахском",
  answerTextOnRu: "Текст ответа на русском",
  answerTextOnFrench: "Текст ответа на французском",
  answerTextOnTurkish: "Текст ответа на турецком",
  answerTextOnUzbek: "Текст ответа на узбекском",
  text: "Текст",
  textOnEn: "Текст на английском",
  textOnKk: "Текст на казахском",
  textOnRu: "Текст на русском",
  textOnFrench: "Текст на французском",
  textOnTurkish: "Текст на турецком",
  textOnUzbek: "Текст на узбекском",
  selectFromList: "Выберите из списка",
  operator: "Оператор",
  before: "До",
  after: "После",
  age: "Возраст",
  brand: "Бренд",
  name: "Имя",
  gender: "Пол",
  activationDate: "Дата активации",
  title: "Наименование",
  titleOnEn: "Наименование на английском",
  titleOnKk: "Наименование на казахском",
  titleOnRu: "Наименование на русском",
  titleOnFrench: "Наименование на французском",
  titleOnTurkish: "Наименование на турецком",
  titleOnUzbek: "Наименование на узбекском",
  dateCreatedSuccessfully: "Дата создана",
  dateDeleted: "Дата удалена",
  apiKeyCreated: "API-Ключ создан",
  apiKeyDeleted: "API-Ключ удален",
  questionCreated: "Вопрос создан",
  questionDeleted: "Вопрос удален",
  surveyCreated: "Опрос создан",
  surveyDeleted: "Опрос удален",
  surveyPaused: "Опрос приостановлен",
  surveyEnded: "Опрос завершен",
  surveyDuplicated: "Опрос продублирован",
  webhookCreated: "Webhook создан",
  webhookDeleted: "Webhook удален",
  qrCreated: "QR Код создан",
  fileDeleted: "Файл удален",
  fileUploaded: "Файл загружен.",
  fileUpload: "Загрузка файла",
  fileDownloaded: "Файл скачан",
  subscribersCountInFile: "Количество респондентов в файле: ",
  defaultError: "Произошла ошибка",
  surveyStarted: "Опрос начат",
  whileSaving: "при сохранении",
  fileAcceptType: "Поддерживаемый формат файла",
  pleaseEnterUserName: "Пожалуйста, введите имя пользователя",
  pleaseEnterPassword: "Пожалуйста, введите пароль",
  web: "Веб",
  sms: "СМС",
  changePassword: "Сменить пароль",
  passwordChange: "Смена пароля",
  passwordChangeConfirm: "Вы уверены, что хотите сменить пароль ？",
  thisQuestionIsCondition: "Этот вопрос является условием для отображения другого вопроса",
  maxFileWeightError: "Максимальный размер файла: 100КБ",
  invalidImageSize: "Недопустимый размер изображения",
  differentAspectRatio: "Разное соотношение сторон",
  rating: "Рейтинг",
  slider: "Слайдер",
  dropdown: "Выпадающий список",
  radioButtons: "Радио-кнопки",
  loyaltyIndex: "Индекс лояльности клиентов (NPS®)",
  customSlider: "Пользовательский слайдер",
  selectType: "Выберите тип",
  maxValue: "Максимальное значение",
  selectMaxValue: "Выберите макс. значение",
  languages: "Языки",
  contacts: "Контакты",
  contactsHelpTxt: "Контакты",
  fact: "Факт",
  russian: "Русский",
  kazakh: "Казахский",
  english: "Английский",
  turkish: "Турецкий",
  uzbek: "Узбекский",
  french: "Французский",
  code: "Код",
  deleteLanguageConfirmation: "Вы уверены, что хотите удалить язык ？",
  languagesHelpTxt: "Список доступных языков для добавления к опросам.",
  languageCreated: "Язык создан",
  languageDeleted: "Язык удален",
  prolongSurvey: "Продлить опрос",
  clear: "Очистить",
  forgotPassword: "Забыли пароль？",
  register: "Зарегистрироваться",
  backToLogin: "Назад к авторизации",
  phoneNumber: "Номер телефона",
  invalidPhoneNumber: "Некорректный номер телефона",
  fullName: "ФИО",
  email: "Электронная почта",
  registration: "Регистрация",
  passwordRecovery: "Восстановление пароля",
  codeValidation: "Валидация кода",
  sendNewPassword: "Отправить новый пароль",
  invalidEmail: "Некорректная электронная почта",
  searchByTags: "Поиск по тегам",
  closeTabText: "Опрос завершен, закройте вкладку используя функции своего браузера.",
  yourAnswer: "Ваш ответ...",
  registrationSuccess: "Регистрация прошла успешно",
  registrationError: "Ошибка регистрации",
  enterValidationCode: "Введите полученный код",
  codeValidationError: "Введен неверный код подтверждения.",
  createdAt: "Создано: ",
  questionsDragDropWarningText:
    "Если у вопроса были условия отображения, они могут не сработать при перемещении вопроса",
  youForgotEndBlock: "Упс! Похоже, Вы забыли Страницу благодарности",
  addAutomaticallyAndStart: "Добавить автоматически и запустить опрос",
  backToQuestionsTable: "Назад к таблице вопросов",
  pageName: "Название страницы",
  pageDisplayCondition: "Условие показа страницы",
  addCondition: "Добавить условие",
  removeCondition: "Удалить условие",
  addQuestion: "Добавить вопрос",
  questionnaire: "Анкета",
  answers: "Ответы",
  variant: "Вариант",
  addAnswerVariant: "Добавить вариант ответа",
  areYouSureYouWantToLeaveEditPage: "Вы уверены, что хотите покинуть страницу редактирования ？",
  youWillLostYourChanges: "Все несохраненные данные будут потеряны.",
  checkYourMailMessage: "Письмо было отправлено вам на почту, пожалуйста проверьте вашу почту.",
  passwordReset: "Сброс пароля",
  linkExpiredMessage: "Похоже, ссылка устарела. Попробуйте снова.",
  mailSendLimitReachedError: "Превышен лимит. Попробуйте позднее.",
  content: "Содержание",
  includeYourChoiceInAnswers: 'Включать в ответы "свой вариант"',
  titleForYourChoice: 'Заголовок для "своего варианта"',
  addParam: "Добавить параметр",
  addCriteria: "Добавить критерий",
  visualRow: "Визуальный ряд",
  questionDisplayLogic: "Логика показа вопроса",
  addConditionAccordingToPrevQuestion: "Добавить условие показа вопроса в зависимости от ответа на предыдущий вопрос",
  displayIfAnswerOn: "Показывать если ответ на: ",
  displayOnlyContactsWhich: "Показывать только контактам, у которых: ",
  newQuestion: "Новый вопрос",
  youHaveUncompletedNewQuestionWarning: "У вас есть несохраненный новый вопрос",
  infoBlockText: "Текст инфо блока",
  iAgreeWith: "Я согласен с ",
  termsOfUse: "Пользовательским соглашением",
  limitReached: "Превышен лимит",
  change: "Изменить",
  identifier: "Идентификатор",
  size: "Размер",
  copyImage: "Скопировать изображение",
  sendImage: "Отправить изображение",
  successCopiedToClipBoard: "Скопировано в буфер обмена",
  accessDenied: "Доступ запрещен.",
  iin: "ИИН",
  addCustomRegexp: "Добавить регулярное выражение вручную",
  regexp: "Регулярное выражение",
  enterRegexp: "Введите регулярное выражение",
  validateResponse: "Валидировать ответ",
  documentation: "Документация",
  defaultLabel: "По умолчанию",
  emailProvidersMainDescription:
    "Вы можете добавить e-mail провайдера из предложенных в списке, чтобы потом выбрать его внутри опроса.",
  emailProviders: "E-mail провайдеры",
  providerDescription: "Описание провайдера",
  connectionName: "Наименование подключения",
  provider: "Провайдер",
  addpostmarkProvider: "Добавить провайдера Postmark",
  changepostmarkProvider: "Изменить провайдера Postmark",
  addsmtpProvider: "Добавить провайдера SMTP",
  changesmtpProvider: "Изменить провайдера SMTP",
  providersName: "Название провайдера",
  emailProviderDeleted: "E-mail провайдер удален",
  emailProviderCreated: "E-mail провайдер создан",
  emailProviderChanged: "E-mail провайдер изменён",
  deleteEmailProviderConfirmation: "Вы уверены, что хотите удалить E-mail провайдер ？",
  titleFont: "Шрифт заголовка",
  textFont: "Шрифт текста",
  selectFont: "Выберите шрифт",
  distributionAndNotification: "Рассылка и уведомления",
  distributionList: "Список рассылок",
  uniqueUrlsAndQrCodes: "Уникальные URL-ы и QR-коды опроса",
  distributionDate: "Дата рассылки",
  distributionChannel: "Канал рассылки",
  respondentsSource: "Источник респондентов",
  uniqueUrlParam: "Уникальный параметр URL",
  surveysURL: "URL опроса",
  fileFromDisc: "Файл с диска",
  from: "с ",
  to: "по ",
  distributionDeleteConfirmation: "Вы уверены, что хотите удалить рассылку ？",
  creatingDistribution: "Создание рассылки",
  editingDistribution: "Редактирование рассылки",
  distributionStartConfirmation: "Вы уверены, что хотите начать рассылку ？",
  distributionFinishConfirmation: "Вы уверены, что хотите завершить рассылку ？",
  distributionPauseConfirmation: "Вы уверены, что хотите приостановить рассылку ？",
  attributeContainingSendingAddress: "Атрибут содержащий адрес отправки",
  attributeContainingSendingAddressHelpText:
    "Атрибут контакта, который содержит номер телефона или электронный адрес получателя.",
  subscriberSelectionLogic: "Логика выборки абонентов",
  anonymousSurvey: "Анонимный опрос",
  fill: "Заполните",
  qrAndUrlCreating: "Создание QR/URL",
  shareQrAnsUrl: "Поделиться QR/URL",
  copy: "Скопировать",
  integrationsDocHelpText: 'См. раздел "Настройки/API Ключи" для создания API-Ключей авторизации',
  triggerSurveySettings: "Настройка триггерных опросов",
  triggerCondition: "Условия срабатывания триггера",
  areYouSureYouWantToDeleteFiltersGroup: "Вы уверены, что хотите удалить группу фильтров ？",
  saveChanges: "Сохранить изменения",
  qrCodeDeleted: "QR-Код удален",
  qrCodeUpdated: "QR-Код изменён",
  qrCodeCreated: "QR-Код создан",
  addFromFile: "Добавить из файла",
  addContact: "Добавить контакт",
  contactsUploadCsvHelpText: "csv (см. пример формата)",
  contactsUploadXlsxHelpText: "xlsx (см. пример формата)",
  contactsUploading: "Загрузка контактов",
  filesType: "Тип файлов",
  source: "Источник",
  upload: "Загрузить",
  contactDeleted: "Контакт удален",
  contactUpdated: "Контакт изменён",
  contactCreated: "Контакт создан",
  noFileSelected: "Выберите файл",
  updateAt: "Дата обновления",
  attributes: "Атрибуты",
  keyField: "Ключевое поле",
  warningWhenUploadingFile: {
    title: "Предупреждение перед загрузкой файла",
    subTitle: "Пожалуйста убедитесь, что следующие условия соблюдены:",
    first: "1. Формат файла соответствует типу файла",
    second: "2. Файл доступен для чтения",
    third: "3. Размер файла не превышает 1 Мб",
    fourth: "4. Формат файла соответствует выбранному типу",
  },
  watchShort: "см.",
  exampleHere: "пример здесь",
  fileUploadingError: "Ошибка загрузки файла",
  deactivated: "Деактивирован",
  atLeastOneDistributionRequired: "Минимум одна рассылка обязательна",
  smsProviders: "SMS Провайдеры",
  smsProvidersMainDescription: "Вы можете добавить SMS провайдера, чтобы потом выбрать его внутри опроса.",
  sender: "Отправитель",
  surveyActivated: "Опрос активирован",
  surveyDeactivated: "Опрос деактивирован",
  activateSurvey: "Активировать",
  deactivateSurvey: "Деактивировать",
  activateSurveyConfirmation: "Вы уверены, что хотите активировать опрос ？",
  deactivateSurveyConfirmation: "Вы уверены, что хотите деактивировать опрос ？",
  distributionFinished: "Рассылка завершена",
  distributionPaused: "Рассылка приостановлена",
  distributionStarted: "Рассылка начата",
  distributionUpdated: "Рассылка изменена",
  distributionCreated: "Рассылка создана",
  distributionDeleted: "Рассылка удалена",
  addAutomaticallyAndStartDistribution: "Добавить автоматически и запустить рассылку",
  atLeastOneQuestionIsRequired: "Для старта рассылки необходимо создать хотя бы один вопрос анкеты",
  distributionsAreTemporaryPausedHelpTxt: "Внимание! Рассылки временно приостановлены в связи с деактивацией опроса.",
  allTempPausedDistributionsWillContinue: "Все временно приостановленные рассылки возобновятся.",
  allDistributionsWillBePausedTemporary: "Все рассылки временно приостановятся.",
  contactsSettings: "Настройки контактов",
  addAttribute: "Добавить атрибут",
  attributeName: "Наименование атрибута",
  datatype: "Тип данных",
  whoAdded: "Кем добавлено",
  whenAdded: "Когда добавлено",
  attributeDeleted: "Атрибут удален",
  attributeCreated: "Атрибут создан",
  attributeUpdated: "Атрибут изменен",
  attributeDeleteConfirmation: "Вы уверены, что хотите удалить атрибут ？",
  newAttribute: "Атрибуты",
  contact: "Контакт",
  contactsSettingsHelpTxt: "Список доступных атрибутов для добавления к контактам.",
  newContact: "Контакты",
  updateContact: "Изменить контакт",
  labelMustBeUnique: "Property key должен быть уникальным",
  contactDeleteConfirmation: "Вы уверены, что хотите удалить контакт ？",
  signInWithGoogle: "Войти через Google",
  review: "Обзор",
  reports: "Отчёты",
  answersByDays: "Ответы по дням",
  loading: "Загрузка",
  generalStatistics: "Общая статистика",
  viewed: "Просмотрено",
  surveysStarted: "Начато опросов",
  avgFinishTime: "Среднее время завершения",
  finishedProportion: "Доля завершенных",
  finishedCount: "Завершено",
  avgTime: "Среднее время",
  byDeviceType: "По типу устройства",
  byLanguage: "По языку",
  byDistributionType: "По типу рассылки",
  numberOfInteractionsByCategory: "Кол-во взаимодействий по категориям",
  beenFinished: "Завершенные",
  notFinished: "Не завершенные",
  resetFilters: "Сбросить фильтры",
  columns: "Колонки",
  deleteConfirmation: "Вы уверены, что хотите удалить строку ？",
  selectColumns: "Выберите колонки",
  columnsNotFound: "Колонки не найдены",
  atLeastOneColumnsMustBeSelected: "Минимум одна колонка должна быть выбрана",
  deleteSelectedElementsConfirmation: "Вы уверены, что хотите удалить все выбранные элементы ？",
  report: "Отчет",
  questionsInSurvey: "Вопросов в опросе",
  answersOption: "Опция ответа",
  percentOfAnswers: "% ответов",
  display: "Показать",
  error: "Ошибка",
  resendCodeAgain: "Отправить код заново",
  resendCode: "Отправить код",
  deletedSuccessfully: "Успешно удалено",
  answered: "отвечено",
  filesUploaded: "Загружено файлов",
  unavailable: "Недоступно",
  formatTime: {
    min: "мин",
    hrs: "час",
    sec: "сек",
    day: "день",
    day2form: "дня",
    days: "дней",
    hour: "час",
    hour2form: "часа",
    hours: "часов",
    minute: "минута",
    minute2form: "минуты",
    minutes: "минут",
    second: "секунда",
    second2form: "секунды",
    seconds: "секунд",
  },
  accountSettings: "Настройки аккаунта",
  myAccount: "Мой аккаунт",
  currentTariff: "Текущий тарифный план",
  timezone: "Часовой пояс",
  currentTimezone: "Текущий часовой пояс",
  tariffPlans: "Тарифные планы",
  tariffPlan: "Тарифный план",
  selectTimezone: "Выберите часовой пояс",
  notChosen: "Не выбрано",
  selectThePlanThatSuitsYouBest: "Выберите наиболее удобный для вас план",
  choose: "Выбрать",
  currentPlan: "Текущий план",
  selectPaymentMethod: "Выберите способ оплаты",
  paymentPage: "Страница оплаты",
  moreAboutTariffs: "Подробнее о тарифах",
  role: "Роль",
  addUser: "Добавить пользователя",
  subUserPageHelpTxt:
    "Добавленные пользователи будут иметь доступ к просмотру опросов друг друга, включая Ваши, в соответствие с выданной ролью.",
  userDeleteConfirmationText: "Вы уверены, что хотите удалить пользователя ？",
  roles: "Роли",
  users: "Пользователи",
  share: "Поделиться",
  shareSurveyResults: "Поделиться результатами опроса",
  accessOptions: "Параметры доступа",
  onlyForRegisteredUsers: "Только для зарегистрированных пользователей",
  allWhoHasLink: "Все, у кого есть ссылка",
  allWhoHasLinkAndPassword: "Все, у кого есть ссылка и пароль",
  sendLink: "Отправить ссылку",
  linkSent: "Ссылка отправлена",
  enterEmail: "Введите e-mail",
  userNotFound: "Пользователь не найден",
  aiAssistant: "AI ассистент",
  extraOptions: "Дополнительные параметры",
  smsProviderDeleted: "SMS провайдер удален",
  smsProviderCreated: "SMS провайдер создан",
  smsProviderChanged: "SMS провайдер изменён",
  invalidUrl: "Неверная ссылка",
  selectAll: "Выбрать все",
  questionsFilter: "Фильтр вопросов",
  selectQuestions: "Выберите вопросы",
  createAndContinue: "Создать и Продолжить",
  downloadQr: "Скачать QR",
  showQr: "Показать QR",
  copyQr: "Скопировать QR",
  createDistribution: "Создать рассылку",
  createQrCode: "Создать QR-код",
  questionsQuantity: "Количество вопросов",
  standard: "Стандартный",
  rounded: "Скругленный",
  dots: "Точки",
  selectColor: "Выберите цвет",
  style: "Стиль",
  deleteLogo: "Удалить лого",
  youHaveUnfilledFields: "У вас есть обязательные поля, которые не заполнены.",
  message: "Сообщение",
  thankYouPage: "Страница благодарности",
  welcomePage: "Страница приветствия",
  footer: {
    termsOfUse: "Условия использования",
    privacyPolicy: "Политика конфиденциальности",
    reference: "Справка",
  },
  distributionChannelCreation: "Создание канала распространения",
  saveAndCopy: "Сохранить и скопировать",
  saveAndShare: "Сохранить и поделиться",
  linkToSurvey: "Ссылка на опрос",
  shareOn: "Поделиться в ",
  emailDistribution: "E-mail рассылка",
  smsDistribution: "SMS-рассылка",
  dateAndTime: "Дата и время",
  additional: "Дополнительно",
  moreDetails: "Подробнее",
  month: "месяц",
  distributionChannelsList: "Список каналов распространения",
  channelName: "Название канала",
  questionMediaBanner: {
    label: "Медиа-баннер",
    title: "Нажмите или перетащите файл в эту область, чтобы загрузить",
    description: "Поддерживаемые файлы: JPG, JPEG, PNG, GIF, MP4.",
    deleteConfirmationText: "Вы уверены, что хотите удалить медиа-баннер?",
    uploadedSuccessfullyText: "Медиа-файл загружен.",
  },
  color: "Цвет",
  fileFormat: "Формат файла",
  buy: "Купить",
  redirectUrl: "URL-адрес перенаправления",
  identifierErrorMsg: "Идентификатор может содержать только латинские буквы, цифры, тире и нижние подчеркивания.",
  socialMedia: "Соц. сети",
  redirectToAnotherSite: "Перенаправлять на другой сайт",
  previewOfTheSurvey: "Предпросмотр опроса",
  firstname: "Имя",
  lastname: "Фамилия",
  youHaveReachedMaxSurveys: "Вы достигли максимального количества доступных опросов для вашей подписки.",
  youHaveReachedMaxContacts: "Превышен лимит контактов",
  showProgressBar: "Отображать прогресс-бар",
  templateTooltips: {
    SINGLE_CHOICE: "Для получения единственного ответа на вопрос из предложенных вариантов",
    MULTIPLE_CHOICE: "Предоставляет возможность  выбрать несколько вариантов ответа на вопрос",
    CSI: "Позволяет респонденту дать оценку разных параметров по предложенным критериям. Эти данные могут помочь рассчитать индекс удовлетворенности клиентов.",
    NPS: "Предложите дать оценку вашу вопросу в виде иконок (“звезда”, “смайлы”, “сердечки”, цифровой ряд”), слайдера, выпадающего списка. Позволяет оценить по 5 или 10-балльной шкале.",
    FILE_UPLOAD:
      "Предоставьте возможность респонденту прикрепить файлы к своему ответу (резюме, портфолио, фото и другие изображения)",
    INFO_BLOCK: "Покажите респонденту произвольное сообщения . Не требует ответа.",
    START_BLOCK:
      "Это стартовая страница опроса. Поприветствуйте респондента и расскажите о своем опросе.  Не требует ответа.",
    END_BLOCK:
      "Завершающая страница опроса отображающая сообщение респонденту в конце опроса. Не требует ответа. Можно перенаправить на ваш сайт.",
    EMAIL: "Шаблон с  возможностью проверки формата вводимого адреса электронной почты",
    IIN: "Шаблон для запроса ИИН",
    NUMBER: "Запросите номер телефона респондента",
    TEXT: "Для сбора ответов на открытые вопросы.",
    DATE: "Запросите у респондента  необходимую дату (для указания прошедшей или будущей даты оказания услуги)",
    DATETIME:
      "Запросите у респондента  необходимую дату и точное время (для указания прошедшей или будущей даты и времени оказания услуги)",
  },
  propertyKey: "Наименование переменной для шаблонов текстов",
  theVariableIsRequiredForTexts:
    "Переменная требуется для использования в текстах при обращении к пользователю. К примеру, вы можете использовать шаблон  сообщения в опросе, где ко всем респондентам будете обращаться по имени.",
  patternErrorText: 'URL-ссылка должна начинаться с "http://" или "https://"',
  dataTypeText: "Текст",
  integerDataType: "Целое число",
  numberWithFractionalPart: "Число с дробной частью",
  stringList: "Список (разделитель “,“)",
  dataTypeDate: "Дата",
  emailDataType: "Электронная почта",
  mobileNumber: "Мобильный номер",
  emailError: "Введите корректный email",
  phoneError: "Введите корректный номер телефона",
  basedOnPreviousResponses: "На основе предыдущих ответов",
  basedOnContactData: "На основе данных контакта",
  highestScoreLabel: "Текст для наивысшей оценки",
  lowestScoreLabel: "Текст для наименьшей оценки",
  npsTrademark:
    "NPS®, Net Promoter и Net Promoter Score являются зарегистрированными товарными знаками компаний Satmetrix Systems, Inc., Bain & Company and Fred Reichheld",
  attentionAnswerNotRequired: "Обратите внимание, ответ на ссылающийся вопрос не обязателен.",
  distributionStartConfirmationTooltip: "Начать рассылку",
  distributionPauseConfirmationTooltip: "Приостановить рассылку",
  distributionFinishConfirmationTooltip: "Остановить рассылку",
  fixedNumberOfSelectedOptions: "Фиксированное количество выбранных вариантов",
  ranging: "Ранжирование",
  mixOptions: "Смешать варианты",
  hideLineNumbers: "Скрыть номера строк",
  noAnswersAdded: "Ответы ещё не добавлены",
  subscriptionSurvey: "Подписка Survey Suite",
  carefully: "Осторожно!",
};
