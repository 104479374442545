import React, { FC, useEffect } from "react";
import { Form } from "antd";
import { I18n } from "react-redux-i18n";
import InputFormItemComponent from "./form/items/input";
import { SurveyLanguage } from "../types/language";

interface ScoreLabelInputProps {
  currentLangIndex: number;
  lang: SurveyLanguage;
}

const ScoreLabelInputComponent: FC<ScoreLabelInputProps> = ({ currentLangIndex, lang }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      answerTemplate: {
        textTranslationsToHighestScoreLabel: {
          [currentLangIndex]: {
            text: "",
            language: {
              langCode: lang.langCode,
              langName: lang.langName,
            },
          },
        },
        textTranslationsToLowestScoreLabel: {
          [currentLangIndex]: {
            text: "",
            language: {
              langCode: lang.langCode,
              langName: lang.langName,
            },
          },
        },
      },
    });
  }, [lang, currentLangIndex]);

  return (
    <div>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <>
            <InputFormItemComponent
              showTextArea={true}
              rows={2}
              maxCharacters={50}
              required={false}
              label={I18n.t("highestScoreLabel")}
              name={["answerTemplate", "textTranslationsToHighestScoreLabel", currentLangIndex, "text"]}
              style={{ width: 260 }}
              initialValue=""
            />
            <Form.Item
              hidden
              name={["answerTemplate", "textTranslationsToHighestScoreLabel", currentLangIndex, "language", "langCode"]}
              initialValue={lang.langCode}
            />
            <Form.Item
              hidden
              name={["answerTemplate", "textTranslationsToHighestScoreLabel", currentLangIndex, "language", "langName"]}
              initialValue={lang.langName}
            />

            <InputFormItemComponent
              showTextArea={true}
              rows={2}
              maxCharacters={50}
              required={false}
              label={I18n.t("lowestScoreLabel")}
              name={["answerTemplate", "textTranslationsToLowestScoreLabel", currentLangIndex, "text"]}
              style={{ width: 260 }}
              initialValue=""
            />
            <Form.Item
              hidden
              name={["answerTemplate", "textTranslationsToLowestScoreLabel", currentLangIndex, "language", "langCode"]}
              initialValue={lang.langCode}
            />
            <Form.Item
              hidden
              name={["answerTemplate", "textTranslationsToLowestScoreLabel", currentLangIndex, "language", "langName"]}
              initialValue={lang.langName}
            />
          </>
        )}
      </Form.Item>
    </div>
  );
};

export { ScoreLabelInputComponent };
