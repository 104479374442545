import React, { FC, useCallback, useState } from "react";
import { HeartFilled, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import { NpsIconEnum } from "../types/npsIconEnum";
import { smileIcons10, smileIcons5, smileIcons6 } from "../types/smileyIcons";

const Rating: FC<{
  readonly iconType?: NpsIconEnum;
  readonly defaultValue: number;
  readonly maxValue: number;
}> = ({ iconType, defaultValue, maxValue }) => {
  const [currentValue, setCurrentValue] = useState<number>(defaultValue);

  const getSmile = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      let icons;
      switch (maxValue) {
        case 5:
          icons = smileIcons5;
          break;
        case 6:
          icons = smileIcons6;
          break;
        default:
          icons = smileIcons10;
      }

      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{icons[index + 1]}</span>;
    },
    [currentValue, maxValue],
  );

  const getNumeric = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{index + 1}</span>;
    },
    [currentValue],
  );

  const getCharacter = useCallback(() => {
    if (isIcon(NpsIconEnum.NUMERIC)) {
      return getNumeric;
    } else if (isIcon(NpsIconEnum.SMILE)) {
      return getSmile;
    } else if (isIcon(NpsIconEnum.HEART)) {
      return <HeartFilled />;
    } else {
      return <StarFilled />;
    }
  }, [iconType, getSmile, getNumeric]);

  function isIcon(type: NpsIconEnum) {
    return iconType === type;
  }

  const handleRateChange = (value: number) => {
    setCurrentValue(value);
  };

  return (
    <Rate
      style={{
        fontSize:
          maxValue === 5
            ? 26
            : isIcon(NpsIconEnum.STAR) || isIcon(NpsIconEnum.HEART)
            ? maxValue === 6
              ? 23
              : maxValue === 10
              ? 13
              : 5
            : maxValue === 6
            ? 20
            : maxValue === 10
            ? 10
            : 3,
      }}
      value={currentValue}
      onChange={handleRateChange}
      count={isIcon(NpsIconEnum.SMILE) ? maxValue : maxValue}
      character={getCharacter()}
    />
  );
};

export default Rating;
