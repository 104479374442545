import React, { FC, useRef, useState } from "react";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import "./index.scss";
import { Button, Form, Popover } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { REQUIRED_MESSAGE } from "../../utils/getRequiredMessage";

type Props = {
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly name: string | (string | number)[];
  readonly label: string;
  readonly showEmoji?: boolean;
};

const MdxEditor: FC<Props> = (props) => {
  const { name, label, disabled, showEmoji } = props;
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<any>(null);

  const formProps = {
    ...props,
    rules: [{ required: props.required, message: REQUIRED_MESSAGE() }],
  };

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue, setFieldValue }) => {
        const markdownValue$: string = getFieldValue(name) || "";

        const handleMarkdownChange$ = (newMarkdownValue: string) => {
          setFieldValue(name, newMarkdownValue);
        };

        const handleEmojiSelect = (emoji: any) => {
          if (editorRef.current) {
            const newMarkdownValue = markdownValue$ + emoji.native;
            editorRef.current.insertMarkdown(emoji.native);
            handleMarkdownChange$(newMarkdownValue);
            editorRef.current.setMarkdown(newMarkdownValue);
          }
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item {...formProps} label={label} name={name} style={{ flex: 1 }}>
              <MDXEditor
                ref={editorRef}
                markdown={markdownValue$}
                contentEditableClassName="contentEditable"
                onChange={(newValue: string) => {
                  if (disabled) {
                    return;
                  }
                  handleMarkdownChange$(newValue);
                }}
                className={`editor-container ${disabled ? "editor-disabled" : ""}`}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        <ListsToggle />
                        <BlockTypeSelect />
                      </>
                    ),
                  }),
                ]}
              />
            </Form.Item>
            {showEmoji && (
              <Popover
                content={
                  <div ref={emojiPickerRef}>
                    <Picker
                      data={data}
                      onEmojiSelect={handleEmojiSelect}
                      locale={"ru"}
                      position={"none"}
                      theme={"light"}
                    />
                  </div>
                }
                title="Pick an emoji"
                trigger="click"
                open={emojiPickerVisible}
                onOpenChange={setEmojiPickerVisible}
              >
                <Button
                  type="text"
                  icon={<SmileOutlined />}
                  onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                />
              </Popover>
            )}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default MdxEditor;
