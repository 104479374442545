import { Button, message } from "antd";
import React from "react";
import { SurveyStatus, SurveyType } from "../../types/surveyType";
import Confirm from "../confirm";
import { I18n, Translate } from "react-redux-i18n";
import { isSurveyCreatingAvailable } from "../../utils/appLimits";

export const content = (data: {
  survey: SurveyType;
  onActivate: () => void;
  onDeactivate: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
}) => {
  const isSurveyInStatus = (status: SurveyStatus) => {
    return data.survey?.status === status;
  };

  return (
    <div style={{ display: "grid" }}>
      {isSurveyInStatus(SurveyStatus.DEACTIVATED) ? (
        <>
          <Confirm
            success
            onConfirm={data.onActivate}
            action={I18n.t("activateSurvey")}
            title={I18n.t("activateSurveyConfirmation")}
            description={I18n.t("allTempPausedDistributionsWillContinue")}
          />
          <Button type={"text"} onClick={data.onEdit}>
            <Translate value={"show"} />
          </Button>
          <Confirm
            onConfirm={data.onDuplicate}
            action={I18n.t("createDuplicate")}
            title={I18n.t("createDuplicateConfirmation")}
          />
        </>
      ) : (
        <>
          <Button type={"text"} onClick={data.onEdit}>
            <Translate value={"edit"} />
          </Button>
          <Confirm
            onConfirm={() => {
              if (!isSurveyCreatingAvailable()) {
                return message.error(I18n.t("youHaveReachedMaxSurveys"));
              }
              data.onDuplicate();
            }}
            action={I18n.t("createDuplicate")}
            title={I18n.t("createDuplicateConfirmation")}
          />
          <Confirm
            danger
            onConfirm={data.onDeactivate}
            action={I18n.t("deactivateSurvey")}
            title={I18n.t("deactivateSurveyConfirmation")}
            description={I18n.t("allDistributionsWillBePausedTemporary")}
          />
        </>
      )}
      {!isSurveyInStatus(SurveyStatus.DELETED) && (
        <Confirm
          danger
          onConfirm={data.onDelete}
          action={I18n.t("deleteSurvey")}
          title={I18n.t("deleteSurveyConfirmation")}
        />
      )}
    </div>
  );
};
