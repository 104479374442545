import "../index.scss";
import React, { FC } from "react";
import { Form } from "antd";
import { HeartOutlined, SmileOutlined, StarOutlined } from "@ant-design/icons";
import { NpsTypeEnum, npsTypeTitles } from "../../../../../../types/npsInfoType";
import SelectFormItemComponent from "../../../../../../components/form/items/select";
import { I18n } from "react-redux-i18n";
import CustomTemplate from "./custom";

const iconsList = [
  { value: "star", label: <StarOutlined /> },
  { value: "heart", label: <HeartOutlined /> },
  { value: "smile", label: <SmileOutlined /> },
  {
    value: "numeric",
    label: (
      <small
        style={{
          fontSize: 10,
          padding: "0 4px",
          borderRadius: 50,
          fontWeight: "bold",
          border: "1px solid black",
        }}
      >
        12345
      </small>
    ),
  },
];

const NpsTemplate: FC = () => {
  return (
    <div>
      <Form.Item shouldUpdate noStyle>
        {({ setFieldValue }) => {
          function onChange() {
            setFieldValue(["answerTemplate", "npsInfoParams", "icon"], undefined);
          }

          return (
            <SelectFormItemComponent
              values={Object.values(NpsTypeEnum).map((key) => ({
                value: key,
                label: npsTypeTitles()[key],
              }))}
              name={["answerTemplate", "npsInfoParams", "type"]}
              placeHolder={I18n.t("selectType")}
              label={I18n.t("type")}
              style={{ width: 300 }}
              hasFeedback={false}
              onChange={onChange}
              required
            />
          );
        }}
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <div style={{ width: "40%" }}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldValue }) => {
              const type = getFieldValue(["answerTemplate", "npsInfoParams", "type"]);
              if (type !== NpsTypeEnum.RATING) {
                return undefined;
              }

              function onChange(iconName: string) {
                if (iconName === "smile") {
                  setFieldValue(["answerTemplate", "npsInfoParams", "maxGrade"], 5);
                }
              }

              return (
                <SelectFormItemComponent
                  name={["answerTemplate", "npsInfoParams", "icon"]}
                  placeHolder={I18n.t("selectIcon")}
                  label={I18n.t("icon")}
                  style={{ width: 200 }}
                  onChange={onChange}
                  hasFeedback={false}
                  values={iconsList}
                  required
                />
              );
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const grades =
                getFieldValue(["answerTemplate", "npsInfoParams", "icon"]) === "smile" ? [5, 6, 10] : [5, 6, 10];

              return (
                <SelectFormItemComponent
                  values={grades.map((it) => ({ label: it, value: it }))}
                  name={["answerTemplate", "npsInfoParams", "maxGrade"]}
                  placeHolder={I18n.t("selectMaxValue")}
                  label={I18n.t("maxValue")}
                  style={{ width: 100 }}
                  hasFeedback={false}
                  required
                />
              );
            }}
          </Form.Item>
        </div>
      </div>
      <CustomTemplate />
    </div>
  );
};

export default NpsTemplate;
