export const smileIcons5: Record<number, string> = {
  1: "😠",
  2: "🙁",
  3: "😐",
  4: "🙂",
  5: "😊",
};

export const smileIcons6: Record<number, string> = {
  1: "😡",
  2: "😠",
  3: "🙁",
  4: "🙂",
  5: "😊",
  6: "🥰",
};

export const smileIcons10: Record<number, string> = {
  1: "😡",
  2: "😠",
  3: "😦",
  4: "☹️",
  5: "🙁",
  6: "😐",
  7: "🙂",
  8: "😊",
  9: "🥰",
  10: "😍",
};
