import "./index.scss";
import React, { FC, useEffect, useState } from "react";
import { Button, Form, Space } from "antd";
import HiddenValueFormItemComponent from "../../../../../components/form/items/hiddenValue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getNextOrderValue } from "../../../utils";
import MentionFormItemComponent from "../../../../../components/form/items/mention";
import { SurveyLanguage } from "../../../../../types/language";
import EmptyNA from "../../../../../components/emptyNA";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { QuestionType } from "../../../../../types/questionType";
import { DEFAULT_QUESTION } from "../../../../../utils";
import { sortByOrder } from "../../../../../utils/sortByOrder";
import isWelcome from "../../../../../utils/welcomeSmsChecker";
import { I18n } from "react-redux-i18n";

type TableContentProps = {
  readonly disabled: boolean;
  readonly dataKey: string;
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
  readonly addButtonLabel: string;
  onListLengthChange?: (length: number) => void;
};

export const GrowingList: FC<TableContentProps> = (props) => {
  const { disabled, dataKey, langIndex, lang, addButtonLabel, onListLengthChange } = props;

  const [initialized, setInitialized] = useState(false);
  const [fieldsLength, setFieldsLength] = useState(0);

  const initializeFields = (add: (defaultValue?: any) => void, getFieldValue: (name: any) => any) => {
    const data = getFieldValue(["answerTemplate", dataKey]) || [];
    if (data.length === 0 && !initialized) {
      add({
        order: getNextOrderValue(data || []),
      });
      setInitialized(true);
    }
  };

  useEffect(() => {
    if (onListLengthChange) {
      onListLengthChange(fieldsLength);
    }
  }, [fieldsLength, onListLengthChange]);

  return (
    <Form.List name={["answerTemplate", dataKey]}>
      {(fields, { add, remove }) => {
        if (fields.length !== fieldsLength) {
          setFieldsLength(fields.length);
        }

        return (
          <>
            {fields?.length ? (
              fields.map(({ key, name }) => (
                <Space.Compact key={key} className={"params-input-group"}>
                  <HiddenValueFormItemComponent name={[name, "order"]} />
                  <HiddenValueFormItemComponent name={[name, "textTranslations", langIndex, "language"]} value={lang} />
                  <Form.Item label={I18n.t("variant") + ` ${name + 1}`} style={{ width: "100%" }}>
                    <MentionFormItemComponent
                      name={[name, "textTranslations", langIndex, "text"]}
                      required
                      rows={2}
                      noStyle
                    />
                  </Form.Item>
                  {!disabled && <MinusCircleOutlined onClick={() => remove(name)} />}
                </Space.Compact>
              ))
            ) : (
              <EmptyNA />
            )}
            {!disabled && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  initializeFields(add, getFieldValue);
                  return (
                    <Button
                      style={{ marginBottom: 24 }}
                      type="dashed"
                      onClick={() => {
                        const data = getFieldValue(["answerTemplate", dataKey]);
                        add({
                          order: getNextOrderValue(data || []),
                        });
                      }}
                      icon={<PlusOutlined />}
                    >
                      {addButtonLabel}
                    </Button>
                  );
                }}
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export const useQuestionEditPageSelectorsState = () => {
  return useSelector((state: RootState) => ({
    surveyId: state.surveys.current?.surveyId,
    question: state.questions.current,
    questions: state.questions.list,
    channel: state.surveys.channel,
    surveyColor: state.surveys.current?.themeColor,
    languages: state.languages.surveyLanguages,
  }));
};

export const NEW_QUESTION = (order: number) =>
  ({
    ...DEFAULT_QUESTION,
    order: order,
    name: "",
  } as unknown as QuestionType);

export const mapTextToSurveyUrl = (lang: SurveyLanguage) => ({ language: lang, text: "${SURVEY_URL} " });

export const cutWelcomeMessage = (questions: Array<QuestionType>) => {
  return questions.filter((q) => !isWelcome(q)).sort(sortByOrder);
};
